<template>
  <b-row>
    <b-col
      md="8"
      sm="12"
      class="mx-auto"
    >

      <b-row>

        <b-col v-if="lesson!=null">

          <b-card
            text-variant="center"
            class="card"
          >
            <b-col
              md="12"
              class="p-0 pb-1"
            >
              <div class="d-flex justify-content-between">
                <div class="d-flex align-items-center mb-1">
                  <div v-if="$route.params.type == 'courses-lesson'">
                    <b-button
                      variant="flat-secondary"
                      class="btn-icon"
                      @click="$router.push({name: `${self.usertype}-courses`, params: { data: $route.params, }})"
                    >
                      <span> &#8701; {{ $t('actions.courses') }}</span>
                    </b-button>
                    | &nbsp;&nbsp;
                  </div>

                  <b-button
                    v-else
                    variant="flat-secondary"
                    class="btn-icon"
                    @click="() => {
                      if (lesson.cid) {
                        if (classTestId) {
                          return $router.push({
                            name:`${self.usertype}-class-test`,
                            params:{classId},
                          })
                        }
                        $router.push({
                          name:`${self.usertype}-lessons`,
                          params:{ ...$route.params, id:lesson.cid },
                          query:{group:lesson.gname, type: $route.query.mode},
                        })
                        return
                      }
                      GO_BACK()
                    }"
                  >
                    <feather-icon
                      size="20"
                      icon="ArrowLeftIcon"
                    />
                  </b-button>

                  <span>
                    {{ $t('actions.list-of-lessons') }}
                  </span>
                </div>
              </div>
              <div>
                <b-button
                  v-if="lesson.live_link && !isScormLesson && !hideEbook"
                  class="mb-1"
                  variant="outline-success"
                  @click="showEbook = true"
                >
                  <feather-icon icon="BookOpenIcon" />  {{ $t("common.e-book") }}
                </b-button>
                <b-button
                  v-if="lesson.live_link && !isScormLesson"
                  class="ml-2 mb-1"
                  variant="outline-primary"
                  @click="openLink(null)"
                >
                  <feather-icon icon="ExternalLinkIcon" />  {{ $t('help-videos-moduel.link') }}
                </b-button>
                <b-button
                  v-for="link,index of lesson.page_links"
                  :key="index"
                  v-b-tooltip.hover.v-dark
                  :title="link.description"
                  variant="outline-primary"
                  class="ml-2 mb-1"
                  @click="openLink(link.id)"
                >
                  <feather-icon icon="ExternalLinkIcon" /> {{ link.name }}
                </b-button>
                <b-button
                  v-if="lesson.file"
                  class="ml-2 mb-1"
                  variant="outline-primary"
                  @click="showPdf = true"
                >
                  <feather-icon icon="FileTextIcon" />  {{ $t('labels.pdf') }}
                </b-button>
              </div>

            </b-col>
            <!-- stats -->

            <b-col>
              <b-row>
                <b-col
                  v-if="lesson.image"
                  :md="lesson.image ? '6':'0'"
                  :sm="lesson.image ? '6':'0'"
                >
                  <avatar
                    :image="lesson.image !== 'image not found' ? lesson.image : null"
                    :rounded="true"
                    :default-icon="'book'"
                    class="lesson-image-avt"
                    size="100%"
                  />
                </b-col>

                <b-col
                  :md="lesson.image ? '6':'12'"
                  :sm="lesson.image ? '6':'12'"
                  class="d-block md-d-flex flex-column align-items-baseline"
                >
                  <div
                    class="text-center mt-2"
                    :class="{'text-sm-left':lesson.image}"
                  >
                    <b-card-text>
                      {{ $t('curriculum-pages-module.lesson-group') }}
                    </b-card-text>
                    <h3 class="font-weight-bold text-uppercase">{{ lesson.lesson_group }}</h3>
                    <b-card-text>
                      {{ $t('labels.lesson-name') }}
                    </b-card-text>
                    <span class="font-large-1 font-weight-bold text-uppercase">{{ lesson.name }}</span>
                  </div>
                  <div v-if="lesson.pass_percent"
                       class="mt-2"
                  >
                    <div
                      class="text-cebter"
                      :class="{'text-sm-left':lesson.image}"
                    >
                      <b-card-text v-if="isScormLesson">
                        Required Score
                      </b-card-text>
                      <b-card-text v-else>
                        {{ $t('student-test-module.required-percentage') }}
                      </b-card-text>
                      <span class="font-large-1 font-weight-bold">{{ lesson.pass_percent }}<span v-if="!isScormLesson">%</span></span>
                    </div>
                  </div>
                </b-col>
              </b-row>
            </b-col>
            <!-- image -->

            <!-- stats -->
            <div v-if="!hideCodeStandard"
                 class="mt-2"
            >
              <b-table
                id="lesson-description-table"
                responsive="sm"
                :items="getResultRows"
                borderless
              />
            </div>
            <b-row>
              <b-col v-if="!isScormLesson"
                     class="d-flex justify-content-end"
              >
                <b-button
                  v-if="(isATeacher || isHighLevelUser) && !hideAutoPlay"
                  variant="outline-primary"
                  class="d-flex align-items-center mr-1"
                  :disabled="isProcessing"
                  @click="showModal=true"
                >
                  <b-spinner
                    v-show="isProcessing"
                    class="mr-1"
                    small
                  />
                  {{ $t('actions.auto-play') }}
                </b-button>
                <b-button
                  variant="outline-primary"
                  class="d-flex align-items-center"
                  :disabled="isProcessing"
                  @click="startLesson"
                >
                  <b-spinner
                    v-show="isProcessing"
                    class="mr-1"
                    small
                  />
                  {{ $t('labels.start') }}
                </b-button>
              </b-col>
              <!-- <b-col v-else
                     class="d-flex justify-content-end"
              >
                <b-button
                  variant="outline-primary"
                  class="d-flex align-items-center"
                  :disabled="isProcessing"
                  @click="startScormLesson"
                >
                  <b-spinner
                    v-show="isProcessing"
                    class="mr-1"
                    small
                  />
                  {{ $t('labels.start') }}
                </b-button>
              </b-col> -->
            </b-row>

          </b-card>
        </b-col>
      </b-row>
      <EbookSelector
        v-if="showEbook"
        :lesson="{
            ...lesson,
            lesson_id: lessonId
        }"
        :event-id="eventId"
        @close="showEbook = false"
      />
      <!-- Modal -->
      <b-modal v-model="showModal"
               title="Auto Play Options"
               @cancel="closeModal"
      >
        <h2>{{ $t('student-lab-module.select-option-to-start') }}:</h2>

        <div>
          <div v-for="option in autoPlayOptions"
               :key="option.id"
          >
            <b-form-group>
              <b-form-radio
                v-model="selectedOption"
                :value="option.id"
                name="autoPlayOption"
              >
                {{ option.name }}
              </b-form-radio>
            </b-form-group>
          </div>
        </div>

        <p>{{ $t('student-lab-module.start-lesson-with-option') }}: {{ selectedOption }}</p>

        <template #modal-footer="{}">
          <b-button variant="secondary"
                    @click="closeModal"
          >
            {{ $t('labels.cancel') }}
          </b-button>
          <b-button variant="primary"
                    @click="autoPlayLesson"
          >
            {{ $t('student-lab-module.start-auto-play') }}
          </b-button>
        </template>
      </b-modal>
      <div v-if="isScormLesson">
        <input id="auth_header"
               type="hidden"
               value="86loPDjSps#wx3sW2WtZUD6wFik93z6iDBQKwAqvmwjVa0"
        >
        <div v-if="lesson.live_link">
          <iframe :src="lesson.live_link + `&gec_user_id=${self?.id}&classroom_id=${classId}&round_index=${lesson.round_index}`"
                  :title="lesson.name"
                  frameborder="0"
                  width="100%"
                  height="900px"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowfullscreen
          />
        </div>
      </div>
    </b-col>

    <pdf-viewer
      v-if="activePdf"
      ref="pdfViewer"
      :pdf="activePdf"
      :toggle="showPdf"
      @pdfModalClosed="showPdf = false"
    />
  </b-row>
</template>

<script>
import {
  BCard, BCardText, BButton, BRow, BCol, BTable, BSpinner, VBTooltip, BModal, BFormRadio, BFormGroup,
} from 'bootstrap-vue'
import useJwt from '@/auth/jwt/useJwt'
import {
  USER_TYPE_TEACHER,
} from '@/const/userType'
import { getUserData, isHighLevelUser } from '@/auth/utils'
import Avatar from '@/views/common/components/Avatar.vue'
import EbookSelector from '@/views/student/lesson/components/EbookSelector.vue'
import { engagementSettings } from '@/utils/visibilitySettings'
import PdfViewer from '@/views/common/components/PdfViewer.vue'

export default {
  components: {
    BCard,
    BCardText,
    BRow,
    BCol,
    BTable,
    BButton,
    BSpinner,
    BModal,
    BFormRadio,
    BFormGroup,
    EbookSelector,
    Avatar,
    PdfViewer,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      lessonId: null,
      isProcessing: false,
      lesson: null,
      classId: null,
      self: getUserData(),
      showModal: false,
      startAutoPlay: false,
      selectedOption: 1,
      autoPlayOptions: [
        { id: 1, name: '100% Pass Score' },
        { id: 2, name: 'Score Just Pass' },
        { id: 3, name: 'Fail Then Pass' },
      ],
      showEbook: false,
      hideCodeStandard: 1, // 1 hide, 0 show
      hideAutoPlay: 0,
      hideEbook: 0,
      showPdf: 0,
    }
  },
  computed: {
    eventId() {
      return this.$route.query.event_id
    },
    isScormLesson() {
      return !!this.lesson?.is_scorm_lesson
    },
    hasFailed() {
      return this.data?.score_percentage < (this.data?.required_percentage || 40)
    },
    getResultRows() {
      return this.lesson.standards.map(e => ({
        code: e.code,
        standard: e.name,
      }))
    },
    classTestId() {
      return this.$route.query.testId
    },
    isATeacher() {
      return this.self.usertype === USER_TYPE_TEACHER
    },
    isHighLevelUser() {
      return isHighLevelUser()
    },
    activePdf() {
      return this.lesson?.file || null
    },
  },

  watch: {
    '$route.params.classId': {
      handler(classId) {
        this.classId = parseInt(classId, 10)
      },
      deep: true,
      immediate: true,
    },
  },
  created() {
    this.lessonId = parseInt(this.$route.params.id, 10)
    this.getLessonData()
    this.getClassRoomSettings()
  },
  mounted() {
    window.addEventListener('message', event => {
      if (event.data === 'from-gec-scorm') {
        this.$router.push({
          name: `${this.self.usertype}-lessons`,
          params: { classId: this.classId, id: this.lesson.cid },
        })
      }
    }, false)
  },
  methods: {
    startScormLesson() {
      this.$router.push({
        name: `${this.self.usertype}-scorm-lesson`,
        params: { classId: this.classId, lessonId: this.lessonId },
      })
    },
    getLessonData() {
      this.isProcessing = true
      const params = { classroomId: this.classId }
      useJwt.getLessonEntryData(this.lessonId, { params }).then(res => {
        this.lesson = res.data.data
        console.log(this.lesson)
      }).catch(error => {
        this.showErrorMessage(error)
      }).finally(() => {
        this.isProcessing = false
      })
    },
    closeModal() {
      this.showModal = false
    },
    autoPlayLesson() {
      this.isProcessing = true
      //  this.$router.push({ name: 'student-problems', params: { id: this.lessonId } })
      useJwt.startLessonTest(this.lessonId, { cid: this.classId, autoPlay: true, option: this.selectedOption }).then(() => {
        this.$router.push(
          {
            name: `${this.self.usertype}-problems`,
            params: { id: this.lessonId, type: this.$route.params.type },
            query: {
              testId: this.classTestId, type: this.$route.query.type, autoPlay: true, option: this.selectedOption,
            },
          },
        )
      }).catch(error => {
        if (error.response?.data?.errors) {
          this.showError(error.response?.data?.errors)
        } else {
          this.showErrorMessage(error)
        }
      })
        .finally(() => {
          this.isProcessing = false
        })
    },
    startLesson() {
      this.isProcessing = true
      //  this.$router.push({ name: 'student-problems', params: { id: this.lessonId } })
      useJwt.startLessonTest(this.lessonId, { cid: this.classId }).then(() => {
        this.$router.push(
          {
            name: `${this.self.usertype}-problems`,
            params: { id: this.lessonId, type: this.$route.params.type, option: this.$route.query.option },
            query: {
              testId: this.classTestId,
              type: this.$route.query.type,
              option: this.$route.query.option,
              event_id: this.$route.query.event_id,
              mode: this.$route.query.mode,
            },
          },
        )
      }).catch(error => {
        if (error.response?.data?.errors) {
          this.showError(error.response?.data?.errors)
        } else {
          this.showErrorMessage(error)
        }
      })
        .finally(() => {
          this.isProcessing = false
        })
    },
    getClassRoomSettings() {
      useJwt.getClassRoomSettings(this.classId).then(res => {
        const data = res.data?.data
        const districtSettings = res.data?.district_settings
        // this.hideCodeStandard = +data.find(item => item.setting_name === 'code_standards')?.value || 0
        // this.hideAutoPlay = +data.find(item => item.setting_name === 'auto_play')?.value || 0
        // this.hideEbook = +data.find(item => item.setting_name === 'ebook')?.value || 0

        // check for district and school level settings
        // code standards
        const schoolCodeStandards = +res.data?.data.find(item => item.setting_name === 'code_standards')?.value ?? 1
        const districtCodeStandards = +districtSettings.find(item => item.setting_name === 'code_standards')?.value ?? 1
        this.hideCodeStandard = districtCodeStandards === 1 || schoolCodeStandards === 1 ? 1 : 0

        // auto play
        const schoolAutoPlay = +res.data?.data.find(item => item.setting_name === 'auto_play')?.value ?? 0
        const districtAutoPlay = +districtSettings.find(item => item.setting_name === 'auto_play')?.value ?? 0
        this.hideAutoPlay = districtAutoPlay === 1 || schoolAutoPlay === 1 ? 1 : 0

        // ebook
        const schoolEbook = +res.data?.data.find(item => item.setting_name === 'ebook')?.value ?? 0
        const districtEbook = +districtSettings.find(item => item.setting_name === 'ebook')?.value ?? 0
        this.hideEbook = districtEbook === 1 || schoolEbook === 1 ? 1 : 0

        // fancy audio player
        const fancyAudioPlayer = data.find(item => item.setting_name === 'fancy_audio_player')
        const distFancyAudioPlayer = +districtSettings.find(item => item.setting_name === 'fancy_audio_player')?.value || 0

        if (fancyAudioPlayer) {
          if (!fancyAudioPlayer.value || !distFancyAudioPlayer) fancyAudioPlayer.value = 0
        }

        // remove 'fancy_audio_player' from settings and append new setting
        let settings = data.filter(setting => setting.setting_name !== 'fancy_audio_player')
        settings.push(fancyAudioPlayer)

        // AI settings
        const aiKeys = ['has_chat_bot', 'ai_speech', 'ai_feedback']
        const aiEnabledByDistrict = districtSettings.find(setting => setting.setting_name === 'open_ai')?.value ?? 1
        const aiEnabledBySchool = data.find(setting => setting.setting_name === 'open_ai')?.value ?? 1
        let aiSettings = []

        if (aiEnabledByDistrict && aiEnabledBySchool) {
          aiSettings = engagementSettings(aiKeys, data, districtSettings)
        }
        settings = data.filter(setting => !aiKeys.includes(setting?.setting_name))
        settings.push(...aiSettings)

        // engagement settings
        const engagementKeys = ['game', 'avatar', 'attention']
        const engagementSetting = engagementSettings(engagementKeys, data, districtSettings)
        settings = data.filter(setting => !engagementKeys.includes(setting.setting_name))
        settings.push(...engagementSetting)

        this.$store.dispatch('session/setClassSettings', { settings, selfStudyClassRoom: false })
      })
    },
    openLink(link = null) {
      if (link) {
        window.open(`/lesson-page/${link}`, '_blank')
        return
      }
      const linkToOpen = link ?? this.lesson.live_link
      window.open(linkToOpen, '_blank')
    },
  },
}
</script>
<style>
  #lesson-description-table td {
    word-break: break-all;
  }
</style>

<style scoped>
.lesson-image-avt {
  max-height: 200px;
}
</style>
